<template>
  <div>
    <v-card v-if="!loading & !isError" height="100%">
      <v-toolbar color="#4ECDC4" dark flat>
        <div v-if="isReplace" class="text-h6">コンテンツの差し替え</div>
        <div v-else class="text-h6">{{ contentItem.title }}の追加</div>
      </v-toolbar>
      <v-card-text>
        <!-- URL フォーム -->
        <div v-if="this.contentItem.type == 'url'">
          <v-form ref="form" lazy-validation @submit.prevent>
            <v-text-field v-model="urlName" type="text" maxlength="100" label="タイトルを入力" outlined dense></v-text-field>
            <v-text-field v-model="url" type="url" maxlength="250" label="URLを入力" outlined dense></v-text-field>
          </v-form>
        </div>
        <v-container v-else class="pa-0">
          <v-row class="text-center my-2 mx-0" @dragover.prevent @dragenter="onDragEnter" @dragleave="onDragLeave"
            @drop="onDrop">
            <v-col v-if="isReplace" cols=12 lg=12>
              <div class="d-flex">
                <!-- 変更前 -->
                <div style="width:43%; border:3px solid #4ECDC4; border-radius:10px;" class="pa-2">
                  <v-img :src="(status_pre) ? replaceItem.thumbnail : altImg0" v-on:error="status_pre = false"
                    max-height="250" :aspect-ratop="16 / 9">
                  </v-img>
                  <div class="text-left"><span class="font-weight-bold">{{ replaceItem.fileName }}</span><br>
                    {{ replaceItem.fileSizeConvert }} </div>
                </div>
                <v-icon style="width:14%;" large>{{ icons.arrowRightCircle }}</v-icon>
                <!-- 変更後 -->
                <div style="width:43%; border:3px solid #4ECDC4; border-radius:10px;" class="pa-2">
                  <v-img :src="(keepThumbnail) ? (status_after) ? replaceItem.thumbnail : altImg0 : altImg1"
                    v-on:error="status_after = false" max-height="250" :aspect-ratop="16 / 9">
                  </v-img>
                  <div class="text-left">
                    <span v-if="keepName" class="font-weight-bold">{{ replaceItem.fileName }}</span>
                    <span v-else class="font-weight-bold">{{ convertFileName }}</span>
                    <br> {{ convertFileSize }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col v-if="isReplace" cols=5 lg=12 class="ma-0 pt-1">
              <v-checkbox v-model="keepName" label="名前を保持" color="narekomu" hide-details dense
                class="ma-0 py-0"></v-checkbox>
            </v-col>
            <v-col v-if="isReplace && contentItem.type != 'sound'" cols=7 lg=12 class="ma-0 pt-1">
              <v-checkbox v-model="keepThumbnail" label="サムネイルを保持" color="narekomu" hide-details dense
                class="ma-0 py-0"></v-checkbox>
            </v-col>


            <v-file-input v-model="files" :accept="types[contentItem.type].accept"
              :error-messages="isReplace ? replaceFileErrors : fileErrors" :loading="loading" :hidden="loading"
              :label="`ここに${types[contentItem.type].text}ファイルをドロップまたはクリック`"
              :background-color="isDragging ? 'teal accent-4' : 'null'" color="teal accent-4" counter show-size
              placeholder="" prepend-icon="" outlined :multiple="!isReplace" style="text-align:center;" required
              @input="$v.files.$touch()" @blur="$v.files.$touch()">
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 4" color="teal accent-4 " dark label small><v-icon small dense class="mr-1">{{
                  icons.file }}</v-icon> {{ text }}</v-chip>
                <span v-else-if="index === 4" class="overline grey--text text--darken-3 mx-2">+{{ files.length - 4 }}
                  File(s)</span>
              </template>
            </v-file-input>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" outlined color="#78909C" @click="close">キャンセル
        </v-btn>
        <v-btn color="#1DE9B6" class="white--text"
          @click="(contentItem.type == 'url') ? (isReplace) ? replaceUrl() : saveUrl() : (isReplace) ? replaceContent() : save()"
          :disabled="contentItem.type == 'url' ? false : isReplace ? !replaceCheckFiles : !checkFiles">アップロード
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="isError">
      <DialogError v-on:closeErrorEmit="close" text="エラーが発生し,アップロードが完了しませんでした。" />
    </v-card>
    <v-card v-else-if="isComplete">
      <DialogComplete v-on:closeEmit="close" text="アップロードが完了しました" />
    </v-card>
    <v-card v-else color="#4ECDC4" dark>
      <v-card-text>
        {{ this.workerCount }}/{{ this.compWorkerCount }}アップロード中...
        <br>
        <v-progress-linear indeterminate color="white"></v-progress-linear>
      </v-card-text>
    </v-card>



  </div>
</template> 

<script>
import axios from "axios"
import WorkerUpload from 'worker-loader!@/workers/workerUpload'
import WorkerReplace from 'worker-loader!@/workers/workerReplace'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mdiArrowRightCircle, mdiFile } from '@mdi/js'

const DialogError = () => import('@/components/DialogError.vue');
const DialogComplete = () => import('@/components/DialogComplete.vue');

export default {
  name: 'DialogUpload',
  mixins: [validationMixin],

  validations: {
    files: { required, maxLength: maxLength(10) },
  },
  props: ['contentItem', "isReplace", "replaceItem", "dialog"],
  components: {
    DialogError,
    DialogComplete
  },
  data: () => ({
    icons: {
      "arrowRightCircle": mdiArrowRightCircle,
      "file": mdiFile
    },
    loading: false,
    binary: null,
    isError: false,
    isComplete: false,
    enableRename: false,
    keepName: true,
    keepThumbnail: true,
    status_pre: true,
    status_after: true,
    errorFiles: [],
    files: [],
    url: null,
    urlName: null,
    isDragging: false,
    workerCount: 0,
    compWorkerCount: 0,
    altImg0: require("@/assets/navi.jpg"),
    altImg1: require("@/assets/gen_thumb.png"),
    types: {
      object: {
        accept: [".glb", ".stl", ".obj", ".gltf", ".fbx", ".zip"],
        accept_list: ["glb", "stl", "obj", "gltf", "fbx", "zip"],
        text: "GLB,STL,FBX,OBJ(ZIP)",
      },
      image: {
        accept: ["image/jpeg", "image/png"],
        accept_list: ["jpeg", "png", "jpg", "PNG", "JPEG", "JPG"],
        text: "JPEGまたはPNG",
      },
      pdf: {
        accept: "application/pdf",
        accept_list: ["pdf"],
        text: "PDF",
      },
      sound: {
        accept: [".mp3"],
        accept_list: ["mp3"],
        text: "MP3",
      },
      movie: {
        accept: ".mp4",
        accept_list: ["mp4"],
        text: "MP4",
      },
      url: {
        accept: "",
        accept_list: [],
        text: "urlを入力していください。",
      }

    }

  }),



  computed: {

    convertFileSize() {
      if (this.files) {
        return (Number(this.files.size / 1000 / 1000)).toFixed(3) + " MB";
      }
      else {
        return "- "
      }

    },

    convertFileName() {
      if (this.files.name) {
        return this.files.name.split(".")[0];
      }
      else {
        return "-"
      }

    },

    checkFiles() {
      if (this.files.length == 0) return false;
      else if (this.files.length > 10) return false;
      else if (this.checkFilesExtension()) return false;
      else if (this.checkOverSize()) return false;
      else return true;
    },

    replaceCheckFiles() {
      console.log(this.files)
      if (this.files == null) return false;
      else if (this.checkFilesExtension()) return false;
      else if (this.checkOverSize()) return false;
      else return true;
    },

    fileErrors() {
      let errors = [];
      if (!this.$v.files.$dirty) return errors
      if (this.isReplace && this.files.length == 0) {
        errors.push("ファイルを選択してください");
      }
      if (!this.isReplace && this.files.length > 10) {
        errors.push("1度にアップロードできる数は10個までです");
      }
      if (this.checkOverSize()) {
        errors.push("アップロードできる容量を超えています");
      }
      if (this.checkFilesExtension()) {
        errors.push(`アップロードが可能なのは拡張子が${this.types[this.contentItem.type].text}のファイルのみです`);
      }

      return errors;
    },

    replaceFileErrors() {
      let errors = [];
      if (!this.$v.files.$dirty) return errors
      if (this.files == null || this.files.length == 0) {
        errors.push("ファイルを選択してください");
      }
      if (this.checkOverSize()) {
        errors.push("アップロードできる容量を超えています");
      }
      if (this.checkFilesExtension()) {
        errors.push(`アップロードが可能なのは拡張子が${this.types[this.contentItem.type].text}のファイルのみです`);
      }

      return errors;
    }
  },

  watch: {
    //全てのファイルアップロードが完了したとき
    workerCount: function (newVal) {
      if (this.compWorkerCount != 0 & this.workerCount != 0 & newVal == this.compWorkerCount) {
        this.loading = false;
        this.$store.dispatch("setUserInfo", this.$store.state.auth.login);
        console.log(this.errorFiles)
        if (this.errorFiles.length != 0) this.$emit('flashErrorEmit', this.errorFiles);
        this.complete();
        this.close();
      }
    },

    dialog: function () {
      if (this.dialog & this.isReplace & this.contentItem.type == "url") {
        this.urlName = this.replaceItem.fileName;
        this.url = this.replaceItem.url;
      }
    }

  },

  created() {
    if (this.dialog & this.isReplace & this.contentItem.type == "url") {
      this.urlName = this.replaceItem.fileName;
      this.url = this.replaceItem.url;
    }
  },



  methods: {

    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      if (this.isReplace) this.files = _files[0];
      else {
        for (const file in _files) {
          if (!isNaN(file)) {
            //filesはファイル以外のデータが入っており、ファイルの場合のみキー名が数字になるため
            this.files.push(_files[file]);
          }
        }
      }

    },

    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },

    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    },

    //画像の読み込み
    getBase64(file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
      })
    },

    //現在時刻の取得 AWSからデータを取得しないためここで仮のデータを作成している
    getNowDate() {
      let now = new Date();
      let format = "YYYY/MM/DD hh:mm"
      let MM = (now.getMonth() + 1 < 10) ? "0" + String(now.getMonth() + 1) : now.getMonth() + 1;
      let DD = (now.getDate() < 10) ? "0" + now.getDate() : now.getDate();
      let hh = (now.getHours() < 10) ? "0" + now.getHours() : now.getHours();
      let mm = (now.getMinutes() < 10) ? "0" + now.getMinutes() : now.getMinutes();
      format = format.replace(/YYYY/, now.getFullYear());
      format = format.replace(/MM/, MM);
      format = format.replace(/DD/, DD);
      format = format.replace(/hh/, hh);
      format = format.replace(/mm/, mm);

      return format;
    },

    async save() {
      this.loading = true;
      this.compWorkerCount = this.files.length;
      for (let i = 0; i < this.files.length; i++) {
        const worker = new WorkerUpload()

        let dic = {
          file: this.files[i],
          contentItem: this.contentItem,
          url: `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.contentItem.type}s`,
          headers: { "X-Api-Key": process.env.VUE_APP_KEY, "SessionId": this.$store.state.auth.login.sessionId, "Content-Type": "application/json" }
        };
        worker.postMessage(dic)

        worker.onmessage = e => {
          this.workerCount += 1;
          this.$emit('uploadExeEmit', e.data);
          if (e.data == "error") this.isError = true;
          if (e.data.error == "error") this.errorFiles.push(e.data.fileName)
          worker.terminate()
        }
      }

    },

    // コンテンツの差し替え
    async replaceContent() {
      this.loading = true;
      this.compWorkerCount = 1;
      const worker = new WorkerReplace()
      let fileName = this.keepName ? this.replaceItem.fileName + "." + this.files.name.split(".")[1] : this.files.name;

      let dic = {
        file: this.files,
        fileName: fileName,
        keepThumbnail: this.keepThumbnail,
        contentItem: this.contentItem,
        id: this.replaceItem[`${this.contentItem.type}Id`],
        url: `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.contentItem.type}s`,
        headers: { "X-Api-Key": process.env.VUE_APP_KEY, "SessionId": this.$store.state.auth.login.sessionId, "Content-Type": "application/json" }
      };
      worker.postMessage(dic)

      worker.onmessage = e => {
        this.workerCount += 1;
        this.$emit('replaceExeEmit', e.data, this.keepName);
        // if(e.data=="error") this.isError = true;
        if (e.data.error == "error") this.errorFiles.push(e.data.fileName)
        worker.terminate()
      }

    },


    // ファイルサイズがオーバーしていないかチェックする
    checkOverSize() {
      if (this.files == null || this.files.length == 0) return false;
      let type = (this.contentItem.type != "url" && this.contentItem.type != "navi") ? 'total' : '`${this.contentItem.type}Capacity`';
      let storage = this.$store.state.user_model.userData[type];
      let totalSize = 0;
      let nowUsage = 0;
      if (this.isReplace) {
        totalSize += this.files.size
        nowUsage = totalSize + storage.usage * 1024 * 1024 * 1024 - this.replaceItem.fileSize * 1024 * 1024;
      }
      else {
        for (let i = 0; i < this.files.length; i++) {
          totalSize += this.files[i].size
        }
        nowUsage = totalSize + storage.usage * 1024 * 1024 * 1024;
      }

      return storage.limit * 1024 * 1024 * 1024 <= nowUsage;


    },

    getFileExtension(filename) {
      // get file extension
      if (filename) {
        let extension = filename.split('.').pop();
        return extension;
      }

    },

    checkFilesExtension() {
      let files = this.isReplace ? [this.files] : this.files;
      if (files.length == 0 || files[0] == null) return false;
      for (let i = 0; i < files.length; i++) {
        let ext = this.getFileExtension(files[i].name);
        if (this.types[this.contentItem.type].accept_list.indexOf(ext) < 0) {
          return true;
        }
      }

      return false

    },


    // Urlの保存処理
    saveUrl() {
      this.loading = true;
      let urlItem = {
        "url": this.url,
        "fileName": this.urlName,
        "createTime": this.getNowDate(),
        "id": null
      }
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.contentItem.type}s`,
        headers: { "X-Api-Key": process.env.VUE_APP_KEY, "SessionId": this.$store.state.auth.login.sessionId, "Content-Type": "application/json" },
        data: {
          "url": this.url,
          "FileName": this.urlName,
        },
      }).then((response) => {
        this.loading = false;
        urlItem.id = response.data.results.urlId;
        this.$emit('uploadUrlExeEmit', urlItem);
        this.close();
        this.$store.dispatch("setUserInfo", this.$store.state.auth.login)

      }).catch(reason => {
        console.log(reason.response)
        this.isError = true;
      });
    },

    // Urlの差し替え処理
    replaceUrl() {
      this.loading = true;
      let urlItem = {
        "url": this.url,
        "fileName": this.urlName,
        "id": this.replaceItem.urlId
      }
      axios({
        method: "PUT",
        url: `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.contentItem.type}s`,
        headers: { "X-Api-Key": process.env.VUE_APP_KEY, "SessionId": this.$store.state.auth.login.sessionId, "Content-Type": "application/json" },
        data: {
          "url": this.url,
          "FileName": this.urlName,
          "UrlId": this.replaceItem.urlId
        },
      }).then(() => {
        this.loading = false;
        this.$emit('replaceUrlExeEmit', urlItem);
        this.close();
        this.$store.dispatch("setUserInfo", this.$store.state.auth.login)

      }).catch(reason => {
        console.log(reason.response)
        this.isError = true;
      });
    },

    complete() {
      this.$emit('completeEmit');
    },

    close() {
      this.isComplete = false;
      this.isError = false;
      this.loading = false;
      this.keepName = true;
      this.keepThumbnail = true;
      this.status_pre = true;
      this.status_after = true;
      this.errorFiles = [];
      this.files = [];
      this.url = "";
      this.urlName = "";
      this.compWorkerCount = 0;
      this.workerCount = 0;
      this.$emit('closeEmit')

    },

  },

}

</script>

